<template>
  <div class="ScreeningsUpdatePCBScreeningSchema">
    <div class="TitleWrap">
      <span class="PageTitle">{{ mixWB('PCB_SCREENINGS') }}</span>
      <span
        v-if="currentScreeningSelectedFilterUnitID"
        class="UnitFilterText">{{ mixWB('YOU_ONLY_SEE_FOR_ONE_UNIT') }}</span>
    </div>

    <CloseButton />

    <div
      class="pcbFormWrap"
      v-for="unit in mutatedSelectedUnits"
      :key="unit.id">
      <SectionHeadline :text="unit.getFullTitle()" />
      <PCBScreeningSchema
        :formName="unit.id"
        @save-click="onSaveSchemaClick" />
    </div>

    <NextStepButton
      :title="mixWB('NEXT')"
      :isDisabled="!pcbScreeningStatus.isCompleted" />
  </div>
</template>

<script>
import NextStepButton from '@/components/ScreeningItems/NextStepButton.vue'
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import PCBScreeningSchema from '@/components/Forms/PCBScreeningSchema.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ScreeningsUpdatePCBScreeningSchema',
  computed: {
    ...mapGetters([
      'selectedBuildings',
      'selectedUnits',
      'pcbScreeningStatus',
      'currentScreeningSelectedFilterUnitID',
    ]),
    mutatedSelectedUnits() {
      if (this.currentScreeningSelectedFilterUnitID) {
        return this.selectedUnits.filter(
          (unit) => unit.id === this.currentScreeningSelectedFilterUnitID && !unit.hasParent(),
        )
      }
      return this.selectedUnits.filter((unit) => !unit.hasParent())
    },
  },
  methods: {
    onSaveSchemaClick({ formName: unitID, data, needsTesting }) {
      this.$store.dispatch('setScreeningPCBScreening', { unitID, data, needsTesting })
    },
  },
  components: {
    CloseButton,
    NextStepButton,
    SectionHeadline,
    PCBScreeningSchema,
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdatePCBScreeningSchema
    pageWrap()

  .TitleWrap
    margin-bottom 20px
    .PageTitle
      display block
      font-size 1.125rem
      font-weight bold
      text-transform uppercase
    .UnitFilterText
      display block
      color $color_salmon
      margin-top 5px

  .pcbFormWrap
    margin-bottom 20px
</style>
